import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { Box, Button, Card, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
const darkTheme = createTheme({
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette: {
    background: { default: "black" },
    mode: 'dark',
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Container>     <main><Box p={0.5}><img src='180.png' width={180} height={180}></img></Box>
          <Box p={2}><Typography fontWeight={1000} variant="h4">TRENDAAZ</Typography></Box>
          {/* <Box p={1}><Typography fontWeight={1000} variant="h5">Follow the trend.</Typography></Box> */}
          {/*   <Box p={1}><Divider></Divider></Box> */}
          {/*   <Box m={5}><Paper color='black' elevation={0} sx={{ p: 4 }}><Typography fontWeight={500}  >Trendaaz is the new way to share your everyday life.</Typography>
            <br></br>
            <Typography fontWeight={500}>Every six hours discover a new trend to you to realize with a photo, alone or with your friends.</Typography>
            <br></br>
            <Typography fontWeight={500}>Unlock many trophies after achieving goals.</Typography>
            <br></br>
            <Typography fontWeight={500}>Post a photo on the trend to discover the photos of your friends and other users worldwide.</Typography>
            <br></br>
            <Typography fontWeight={500}>React to photos via a simple, fun and intuitive system.</Typography></Paper></Box> */}



          <Grid container p={5} justify="center" justifyContent="center"
            alignItems="center" spacing={1}>
            <Grid item>
              <a href='https://play.google.com/store/apps/details?id=com.artefacte.trendaaz'><img src='android.png' width={175} height={50}></img></a>
            </Grid>
            <Grid item>
              <a href="https://apps.apple.com/us/app/trendaaz/id6443459222"><img src='ios.png' width={175} height={50}></img></a>
            </Grid>
          </Grid>



        </main></Container>
      </ThemeProvider>
    </div >
  );
}

export default App;
